export const Paths = {
  nl: {
    HOSTEL_OVERVIEW: `/nl/hostel`,
    BOOKING_EXTRA_OPTIONS: `/nl/boeking/extra-opties`,
  },
  de: {
    HOSTEL_OVERVIEW: `/de/hostel`,
    BOOKING_EXTRA_OPTIONS: `/de/buchung/extras`,
  },
  en: {
    HOSTEL_OVERVIEW: `/en/hostel`,
    BOOKING_EXTRA_OPTIONS: `/en/booking/extras`,
  },
  API: {
    BASKET_GET: '/webshop_cart/basket.json',
    BASKET_DELETE:
      '/webshop_cart/basket/hostel/:hostelExternalId/article/:articleItemId.json',
    COMPANY_GET: '/webshop_cart/company.json',
    COMPANY_UPDATE: '/webshop_cart/company.json',
  },
};

export default Paths;
